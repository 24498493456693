import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/404.scss';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page introuvable" />
    <main id={'content-page'} className={'page-404'}>
        <div className="container-404">
            <h1>404</h1>
            <p>Page introuvable</p>
        </div>
    </main>

  </Layout>
)

export default NotFoundPage
